<!-- Simpler component API, only meant for non-interactive plain text -->

<template>
  <slot v-if="!props.text" />
  <Root v-else :disabled="props.disabled">
    <Trigger>
      <slot></slot>
    </Trigger>

    <Content>
      {{ props.text }}
    </Content>
  </Root>
</template>

<script setup lang="ts">
import Content from "./Content.vue";
import Root from "./Root.vue";
import Trigger from "./Trigger.vue";

const props = defineProps<{
  text?: string;
  disabled?: boolean;
}>();
</script>
